import React, { useState } from 'react'
import { Link } from 'gatsby'
import Logo from '../assets/bricoBrowser1line.svg'

const Header = () => {
    const [ isOpen, setIsOpen ] = useState(false)
    const handleClick = () => setIsOpen(!isOpen)

    return (
        <div className=" bg-white border-gray-300 sticky top-0 z-99999 shadow">
            <div className="max-w-screen-xl w-4/5 md:w-full m-auto">
                <nav
                    className="flex items-center z-50 justify-between flex-wrap py-2  tracking-wide">
                    <div className="flex justify-between lg:w-auto w-full border-solid  pb-0">
                        <div className="flex items-center justify-center">
                            <Link to='/' className="text-white pointer"><Logo className="logo" alt="logo"/></Link>
                        </div>
                        <div className="block lg:hidden pr-3 ">
                            <button
                                onClick={()=>handleClick()}
                                id="nav"
                                className="flex items-center z-50 px-3 p-2 border-2 border-textColor rounded text-textColor">
                                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
                                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <div className={`menu w-full flex-grow lg:flex ${isOpen ? 'block' : 'hidden'} lg:items-center text-center lg:w-auto lg:px-3 px-8`}>
                        <div className="containerNav circleBehind text-lg leading-loose flex-grow lg:flex justify-end">
                            {/*<Link to='/download'
                                activeClassName="border-b-4 border-yellowColor"
                                className=" block mx-4 lg:inline-block lg:mt-0 text-textColor no-underline hover:text-textColor">
                Download
    </Link>*/}
                            <Link to='/features'
                                activeClassName="border-b-4 border-yellowColor"
                                className=" block mx-4 lg:inline-block lg:mt-0 text-textColor no-underline hover:text-textColor">
                Features
                            </Link>
                            <Link to='/pricing'
                                activeClassName="border-b-4 border-yellowColor"
                                className=" block mx-4 lg:inline-block lg:mt-0 text-textColor no-underline hover:text-textColor">
                Pricing
                            </Link>
                            <Link to='/blog'
                                activeClassName="border-b-4 border-yellowColor"
                                className=" block mx-4 lg:inline-block lg:mt-0 text-textColor no-underline hover:text-textColor ">
                Blog
                            </Link>
                            <Link to='/company'
                                activeClassName="border-b-4 border-yellowColor"
                                className=" block mx-4 lg:inline-block lg:mt-0 text-textColor no-underline hover:text-textColor">
                About Us
                            </Link>
                            {/*<Link to='https://myportal.bricobrowser.net/'
                                activeClassName="border-b-4 border-yellowColor"
                                className="block md:mx-4 lg:inline-block lg:mt-0 text-white items-center bg-mainColor rounded-md no-underline hover:text-textColor px-3 py-1">
                Login
    </Link>*/}
                        </div>
                    </div>

                </nav>
            </div>
        </div>
    )

}
export default Header